import React from "react";
import { ContainerBase } from "containers/ContainerBase";
import { BannersCarousel } from "../components/banners/BannersCarousel";


export function BannersContainer(props: any, railsContext: any) {
    return () => {
        return (
            <ContainerBase railsContext={railsContext}>
                <BannersCarousel />
            </ContainerBase>
        )
    }
}