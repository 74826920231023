import React, { ReactElement, useContext, useEffect, useState } from "react";
import Carrinho from "../../../types/carrinho";
import { AnuncioVitrine, TypeUsuario, TraducoesVitrine, VitrineContextInterface, MapaLimitesAtingindosVitrine } from "./types/index";
import { VitrineFormatada } from "../../../types/vitrine";

import removerCarrinhoItensInexistentesVitrine from "../common/helpers/removerCarrinhoItensInexistentesVitrine";

//@ts-ignore
const initialState: VitrineContextInterface = {
    reloadVitrine: undefined,
    abrirMapaAssentos: (_params) => { return null },
    modalConfirm() { },
    modalError() { },
    modalSuccess() { },
    continuar: async () => { },
    typeUsuario: 'pdv',
    traducoes: {
        confirmarExclusaoReserva: "Tem certeza? A reserva será removida",
        continuar: "Continuar",
        entendido: "Ok",
        ingressosSelecionados: "Ingressos",
        sim: "Sim",
        nao: "Não",
        naoFoiPossivelRemoverItem: "Não foi possível remover o item",
        totalCompra: "TOTAL",
        taxas: "Taxas",
        semIngressosParaVenda: "Não há ingressos para venda",
        lugar: "Lugar",
        erroGenerico: "Houve um erro. Por favor, tente novamente.",
        escolherNoMapa: "Escolher no mapa",
        aPartirDe: "A partir de",
        comprarButtonWeb: "Comprar",
        semIngressosParaVendaTextWeb: "No momento, todos os ingressos para este evento estão indisponíveis.",
        semIngressosParaVendaTituloWeb: "Ops! Ingressos Indisponíveis",
        removendo: "Removendo",
        assento: "Assento",
        setor: "Setor",
        naoInformado: "Não informado",
        escolhaUmaOpcao: "Escolha uma opção",
        escolhaQuantosIngressos: "Escolha quantos ingressos deseja comprar",
        limiteOpcaoAtingido: "Você selecionou o máximo de ingressos permitidos para o setor",
        limiteSessaoAtingido: "Você comprou o máximo de ingressos permitidos para esta sessão",
        limiteLoteAtingido: "Você comprou o máximo de ingressos permitidos para este lote",
        ingressosLiberadosEmBreveWeb: "Os ingressos serão liberados em breve!",
        vendasEmBreveWeb: "Estamos quase lá! Fique na tela para garantir seu ingresso assim que estiver disponível. Prepare-se para a abertura!",
        vendasGeraisIndisponivelWeb: "No momento, não há ingressos disponíveis para este evento. Por favor, volte mais tarde para verificar a disponibilidade. Se você possui um código promocional, use o botão acima para ativá-lo.",
        redirecionandSegundosWeb: "Caso você não tenha sido redirecionado em até 20 segundos,",
        cliqueAquiWeb: "clique aqui",
        aberturaDeVendasEmWeb: "Abertura de vendas em:",
        diaWeb: "Dia",
        horaWeb: "Hora",
        minutoWeb: "Minuto",
        segundoWeb: "Segundo"
    },
    permiteUtilizarCodigo: false,
    exibirLoteSeparado: true,
}

export const VitrineContext = React.createContext<VitrineContextInterface>(initialState);

export function useVitrineContext(): VitrineContextInterface {
    return useContext(VitrineContext);
}

export interface VitrineProviderParams extends Omit<VitrineContextInterface, 'traducoes'> {
    vitrine: VitrineFormatada;
    anuncio: AnuncioVitrine;
    typeUsuario: TypeUsuario;
    carrinho: Carrinho;
    children: ReactElement | ReactElement[];
    traducoes?: TraducoesVitrine;
}

export default function VitrineProvider({ 
    vitrine, anuncio, carrinho,
    abrirMapaAssentos, salvarCarrinho, continuar, 
    modalConfirm, modalError, modalSuccess,
    permiteUtilizarCodigo, reloadVitrine, typeUsuario, children, 
    adicionarCarrinhoItem, removerCarrinhoItem, removerReservaCarrinho, setCarrinho,
    reservarAutomaticamenteItensCarrinho,
    exibirLoteSeparado = initialState.exibirLoteSeparado, esconderInteira = false, 
    traducoes = initialState.traducoes, esconderMeia = false, 
    habilitarSelecaoAutomaticaDeAssentos = false, configAutorizacao }: VitrineProviderParams) {

    useEffect(() => {
        if(carrinho && vitrine) {
            const result = removerCarrinhoItensInexistentesVitrine(vitrine, carrinho);
            if(result.changed) {
                setCarrinho({ carrinho: result.carrinho });
            }
        }
    }, [vitrine, carrinho]);

    return (
        <VitrineContext.Provider value={{
            vitrine,
            anuncio,
            carrinho,
            abrirMapaAssentos,
            salvarCarrinho,
            setCarrinho,
            adicionarCarrinhoItem,
            removerReservaCarrinho,
            removerCarrinhoItem,
            continuar,
            modalConfirm,
            modalError,
            modalSuccess,
            traducoes,
            permiteUtilizarCodigo,
            esconderInteira,
            esconderMeia,
            reloadVitrine,
            typeUsuario,
            exibirLoteSeparado,
            habilitarSelecaoAutomaticaDeAssentos,
            reservarAutomaticamenteItensCarrinho,
            configAutorizacao,
        }}>
            {children}
        </VitrineContext.Provider>
    );
}