import { useQuery } from "@tanstack/react-query";
import { Banner } from "../types"
import { getBanners } from "./api/banners";
import { getFriendlyErrorMessage } from "core/helpers/getFriendlyErrorMessage";

type UseBannersResult = {
    banners?: Banner[];
    isLoadingBanners: boolean;
    errorLoadingBanners: string | undefined;
    reloadBanners(): void;
}

export function useBanners(): UseBannersResult {
    const { data, isLoading, refetch, error } = useQuery({
        queryKey: ['banners'],
        queryFn() {
            return getBanners();
        }
    });


    return {
        banners: data,
        errorLoadingBanners: error ? getFriendlyErrorMessage(error, "Houve um erro ao carregar os banners") : undefined,
        isLoadingBanners: isLoading,
        reloadBanners: refetch,
    }
}